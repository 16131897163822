<app-page-layout>

  <app-page-loader></app-page-loader>

  <app-alert-message></app-alert-message>
  <!-- <app-speech-recognition></app-speech-recognition> -->
  <router-outlet *ngIf="!isLazyLoadModuleLoading;else loadingTemplate"></router-outlet>

  <ng-template #loadingTemplate>

    <div class="alert alert-info text-xs-center">
      <i class="fa fa-spinner fa-spin"></i>{{ 'Loading' | translate }}
    </div>

  </ng-template>

</app-page-layout>
