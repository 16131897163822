import {registerLocaleData} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import {Injector, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {JwtModule, JWT_OPTIONS} from '@auth0/angular-jwt';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app.routing.module';
import {AppInjector} from './core/app-injector.service';
import {TranslateLoaderFactory} from './core/localization/translate-loader.factory';
import {AuthenticationService} from './core/auth/authentication.service';
import {CoreConstants} from './core/core.constants';
import {CoreModule} from './core/core.module';
import {ErrorComponent} from './core/error-pages/custom-error/custom-error.component';
import {PageNotFoundComponent} from './core/error-pages/page-not-found/page-not-found.component';
import {UnAuthenticatedComponent} from './core/error-pages/unauthenticated/unauthenticated.component';
import {UnAuthorizedComponent} from './core/error-pages/unauthorized/unauthorized.component';
import {LoginComponent} from './modules/login/login.component';
import {PageLayoutModule} from './shared/page-layout/page-layout.module';
import {SharedModule} from './shared/shared.module';
import {UserService} from './modules/user/user.service';
import {LocalStorage} from './core/stores/local-storage';
import {ForgotPasswordComponent} from './modules/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './modules/reset-password/reset-password.component';
import {ChartModule} from 'angular2-highcharts';
import {PDFExportModule} from '@progress/kendo-angular-pdf-export';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {PopupService} from './shared/components/popup/popup.service';
import {NgxPaginationModule} from 'ngx-pagination';
// import { NgxAudiostreamModule } from 'ngx-audiostream';
import { SpeechRecognitionComponent } from './shared/components/speech-recognition/speech-recognition.component';
// import { NgxSpeechRecognitionModule } from 'ngx-speech-recognition';
// import { SpeechRecognitionServiceComponent } from './shared/components/speech-recognition/speech-recognition.service';

registerLocaleData(localeEn, 'en');

export function jwtOptionsFactory(localStorage: LocalStorage) {
    return {
        tokenGetter: () => {
            return localStorage.get(CoreConstants.AccessToken);
        },
        authScheme: 'token ',
        whitelistedDomains: ['localhost:8200', '10.21.12.115:8887', '52.183.29.163:8887', 'localhost:4200', '127.0.0.1:4200', '127.0.0.1:5000']
    };
}

@NgModule({

    declarations: [
        AppComponent,
        LoginComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        ErrorComponent,
        UnAuthorizedComponent,
        PageNotFoundComponent,
        UnAuthenticatedComponent,
        // SpeechRecognitionComponent,
        // SpeechRecognitionServiceComponent,
    ],
    imports: [
        BrowserModule,
        // NgxAudiostreamModule,
        // NgxSpeechRecognitionModule,
        BrowserAnimationsModule,
        SharedModule,
        AppRoutingModule,
        NgxPaginationModule,
        CoreModule.forRoot(),
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [LocalStorage],
            },
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: TranslateLoaderFactory,
                deps: [HttpClient]
            }
        }),
        PageLayoutModule,
        PDFExportModule,
        NgbModule,
    ],
    providers: [
        AuthenticationService,
        UserService,
        PopupService,
        // SpeechRecognitionServiceComponent
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule {
    constructor(injector: Injector) {
        AppInjector.setInjector(injector);
    }
}
