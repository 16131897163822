import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
// import { SpeechRecognitionServiceComponent } from '../speech-recognition-service/speech-recognition-service.component';\
import { SpeechRecognitionServiceComponent } from './speech-recognition.service';
import { Subject } from 'rxjs';
declare var webkitSpeechRecognition: any;

@Component({
  selector: 'app-speech-recognition',
  templateUrl: './speech-recognition.component.html',
  styleUrls: ['./speech-recognition.component.css'],
  // providers: [SpeechRecognitionServiceComponent]
})
export class SpeechRecognitionComponent implements OnInit {
  // text: string;
  @Input() inputText: string = '';
  @Output() onTextChange: EventEmitter<any> = new EventEmitter<any>();
  
  recognition =  new webkitSpeechRecognition();
  isStoppedSpeechRecog = false;
  public text = '';
  tempWords;
  textSubject = new Subject<string>();
  transcript: any;
  micFlag = true;

  constructor(
    // public service : SpeechRecognitionServiceComponent
  ) { 
    // this.service.init()
   }

  
  ngOnInit(): void {
    this.recognition.interimResults = true;
    this.recognition.lang = 'en-US';

    this.recognition.addEventListener('result', (e) => {
      const transcript = Array.from(e.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join('');
      this.tempWords = transcript;
      // this.textSubject.next(transcript);
      // this.text = this.text + ' ' + transcript + '.';
      // console.log('this.text ',this.text, ' this.tempWords ',this.tempWords)

      console.log('transcript',transcript);
      // this.onTextChange.emit(this.text);

      

    });
  }

  startService(){
    // this.service.start()
    this.isStoppedSpeechRecog = false;
    this.recognition.start();
    this.recognition.addEventListener('end', (condition) => {
      if (this.isStoppedSpeechRecog) {
        this.micFlag = true;
        this.recognition.stop();
      } else { 
        this.stopService();
        if (this.inputText !== this.text && this.inputText !== undefined ) {
          this.text = this.inputText;
        }
        this.wordConcat()
        this.recognition.start();
        this.micFlag = false; 
        // this.onTextChange.emit(this.text);
      }
    });

  }
  wordConcat() {
    this.text = this.text + ' ' + this.tempWords;
    // console.log('this.text ',this.text, ' this.tempWords ',this.tempWords, 'this.textSubject ',this.textSubject)
    this.tempWords = '';
    this.onTextChange.emit(this.text);

  }
  stopService(){
    // this.service.stop()
    this.isStoppedSpeechRecog = true;
    // this.wordConcat()
    this.recognition.stop();
    // console.log("End speech recognition....")
  }

}
