import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {WidgetLoaderComponent} from '../core/loader/widget-loader/widget-loader.component';
import {HasPermissionDirective} from '../core/directives/has-permission.directive';
import {TranslateModule} from '@ngx-translate/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CustomDecimalPipe} from '../core/pipes/decimal.pipe';
import {AlertMessageService} from './services/alert-message.service';
import {ConfirmantionService} from './services/confirmation.service';
import {AlertMessageComponent} from './components/alert-message/alert-message.component';
import {PageLoaderComponent} from '../core/loader/page-loader/page-loader.component';
import {CustomCurrencyPipe} from '../core/pipes/currency.pipe';
import {PaginationComponent} from './components/pagination/pagination.component';
import {ChartComponent} from './components/charts/chart.component';
import {ChartService} from './components/charts/chart.service';
import {ExcelService} from './services/excel.service';
import {AllowOnlyAlphabetsDirective} from './directives/allow-only-alphabets.directive';
import {DateTimePipe} from '../core/pipes/date-time.pipe';
import {DlDateTimePickerDateModule} from 'angular-bootstrap-datetimepicker';
import {FileUploadComponent} from './components/file-upload/file-upload.component';
import {PopupComponent} from './components/popup/popup.component';
import {PopupService} from './components/popup/popup.service';
import { SpeechRecognitionComponent } from './components/speech-recognition/speech-recognition.component';

@NgModule({
    declarations: [
        /* Pipes */
        CustomDecimalPipe,
        CustomCurrencyPipe,
        DateTimePipe,

        /* Components */
        AlertMessageComponent,
        PageLoaderComponent,
        WidgetLoaderComponent,
        ChartComponent,
        PaginationComponent,
        FileUploadComponent,
        SpeechRecognitionComponent,

        /* Directives */
        HasPermissionDirective,
        AllowOnlyAlphabetsDirective,
        PopupComponent,
    ],
    entryComponents: [PopupComponent],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        NgbModule,
        TranslateModule,
        DlDateTimePickerDateModule
    ],
    exports: [
        /* Pipes */
        CustomDecimalPipe,
        CustomCurrencyPipe,
        DateTimePipe,

        /* Components */
        AlertMessageComponent,
        PageLoaderComponent,
        WidgetLoaderComponent,
        ChartComponent,
        PaginationComponent,
        FileUploadComponent,
        SpeechRecognitionComponent,

        /* Directives */
        HasPermissionDirective,
        AllowOnlyAlphabetsDirective,

        /* Modules */
        CommonModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        NgbModule,
        TranslateModule,
        DlDateTimePickerDateModule,
        PopupComponent,
        
    ],
    providers: [
        /* Pipes */
        CustomDecimalPipe,
        CustomCurrencyPipe,
        DateTimePipe,

        /* Services */
        AlertMessageService,
        ConfirmantionService,
        ChartService,
        ExcelService,
        PopupService
    ]
})

export class SharedModule {
}
