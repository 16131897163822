import {Injectable} from '@angular/core';
import {HttpClientService} from '../../../core/http-client/http-client.service';
import {AuthenticationService} from '../../../core/auth/authentication.service';
import {HttpRequestParams} from 'src/app/core/http-client/http-request-params';
import {HttpMethods} from 'src/app/core/http-client/http-methods.enum';
import {Observable} from 'rxjs';
import {School} from '../../../modules/school/school';


@Injectable({
    providedIn: 'root'
})
export class PopupService {

    constructor(private httpClientService: HttpClientService, private authenticationService: AuthenticationService) {
    }

    getfollowlist(observationId: any): Observable<any[]> {
        const httpParamRequest = new HttpRequestParams();
        httpParamRequest.httpMethod = HttpMethods.GET;
        httpParamRequest.url = `followuplist?observation_id=${observationId}`;
        return this.httpClientService.httpRequest(httpParamRequest);
    }

    addfollowitem(data: any): Observable<any[]> {
        console.log(data);
        const httpParamRequest = new HttpRequestParams();
        httpParamRequest.httpMethod = HttpMethods.POST;
        httpParamRequest.url = `createfollowup`;
        httpParamRequest.params = data;
        return this.httpClientService.httpRequest(httpParamRequest);
    }

    updatefollowitem(data: any): Observable<any[]> {
        console.log(data);
        const httpParamRequest = new HttpRequestParams();
        httpParamRequest.httpMethod = HttpMethods.PUT;
        httpParamRequest.url = `updatefollowup`;
        httpParamRequest.params = data;
        return this.httpClientService.httpRequest(httpParamRequest);
    }
}
